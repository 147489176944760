<template>
  <div v-if="$store.getters.ready">
    <Header nav="profile" active="billing" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix mb-3">
            <div class="page-title-left">
              <h5 class="page-title-heading mt-1 py-2">Billing & Payments</h5>
            </div>
          </div>

          <div class="widget-list">

            <div class="row">
              <div class="col-md-3 pr-3 d-none d-md-block">
                <h6 class="box-title mb-3">Balance</h6>
                <p>Your remaining account balance.</p>
              </div>
              <div class="col-md-9">
                <div class="widget-holder">
                  <div class="widget-bg">
                    <div class="widget-body">
                      <h4 class="my-2" v-if="lastPayment == ''">{{ balance | formatAmount(true) }}</h4>
                      <div v-else>
                        <h4 class="mt-0">{{ balance | formatAmount(true) }}</h4>
                        <div class="small text-muted">Last payment {{ lastPayment }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 pr-3 d-none d-md-block">
                <h6 class="box-title mb-3">Payment Account</h6>
                <p>The credit card to bill for your projects.</p>
              </div>
              <div class="col-md-9">
                <div class="widget-holder">
                  <div class="widget-bg">
                    <div class="widget-body">
                      <div class="row">
                        <div class="col-lg">
                          <h4 class="my-2" v-if="settings.card_brand == ''">None</h4>
                          <div v-else>
                            <div v-if="settings.company !== ''">
                              <h4 class="mt-0 mb-1">{{ settings.company }}</h4>
                              <div>{{ settings.name }}</div>
                            </div>
                            <h4 v-else class="mt-0 mb-1">{{ settings.name }}</h4>
                            <div>{{ settings.address1 }}</div>
                            <div>{{ settings.address2 }}</div>
                            <div>{{ settings.city }} {{ settings.state }} {{ settings.zip }}</div>
                            <div>{{ settings.country | formatCountry }}</div>
                          </div>
                        </div>
                        <div class="col-lg">
                          <div v-if="settings.card_brand != ''">
                            <h4 class="mt-lg-0 mt-3">{{ settings.card_brand }} &bull;&bull;&bull;&bull;{{ settings.card_last4 }}</h4>
                            <div class="small text-muted">{{ settings.frequency | formatFrequency }} automatic payments</div>
                          </div>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <button type="button" class="btn btn-primary btn-rounded mt-4 mt-lg-0" @click="openUpdateModal()">Update</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 pr-3 d-none d-md-block">
                <h6 class="box-title mb-3">Transactions</h6>
                <p>A complete history of the charges and payments on your account</p>
              </div>
              <div class="col-md-9">
                <div v-for="(m, index) in months" :key="m">
                  <div class="widget-holder mb-0">
                    <div class="widget-bg">
                      <div class="widget-body">
                        <div class="row">
                          <div class="col">
                            <h5 class="mt-2 pt-1">{{ $datefns.utc.format(m, 'MMMM yyyy') }}</h5>
                          </div>
                          <div class="col text-right">
                            <a :href="`${$api.defaults.baseURL}/profile/billing/statement/${$datefns.utc.format(m, 'yyyy-MM')}`" class="btn btn-default btn-rounded" role="button"><i class="fal fa-print mr-2"></i>Print</a>
                          </div>
                        </div>
                        <hr>
                        <h6 v-if="currentMonth === m" class="fw-400 text-right mr-2">Current Balance: {{ endingBalance(m) | formatAmount(true) }}</h6>
                        <h6 v-else class="fw-400 text-right mr-2">Ending Balance: {{ endingBalance(m) | formatAmount(true) }}</h6>
                        <table class="table">
                          <thead><tr><th style="width: 130px;">Date</th><th>Description</th><th class="text-right">Amount ($)</th></tr></thead>
                          <tbody>
                            <tr v-for="tx in txMonthGroup(m)" :key="tx.id">
                              <td>{{ $datefns.utc.format(tx.created_at, 'MMM d') }}{{ tx.end_at ? ` - ${$datefns.utc.format(tx.created_at, 'MMM d')}` : '' }}</td>
                              <td>{{ tx.description }}</td>
                              <td class="text-right">{{ tx.amount | formatAmount(false) }}</td>
                            </tr>
                          </tbody>
                        </table>
                        <h6 class="fw-400 text-right mr-2">Beginning Balance: {{ beginningBalance(m) | formatAmount(true) }}</h6>
                      </div>
                    </div>
                  </div>
                  <div v-if="index < months.length - 1">
                    <div class="issuedetails-connect"></div>
                    <div v-if="$datefns.formatDistance(new Date(months[index + 1]), new Date(m)).includes('year') || ($datefns.formatDistance(new Date(months[index + 1]), new Date(m)).includes('month') && $datefns.formatDistance(new Date(months[index + 1]), new Date(m)) != '1 month')">
                      <div class="clearfix issuedetails-action">
                        <div class="float-left issuedetails-action-icon"><i class="fal fa-clock fa-fw text-muted rounded-circle"></i></div>
                        <div class="float-right issuedetails-action-description text-muted pt-2 mt-1">No transactions for {{ $datefns.formatDistance(new Date(months[index + 1]), new Date(m)) }}</div>
                      </div>
                      <div class="issuedetails-connect"></div>
                    </div>
                  </div>
                </div>
                <div v-if="settings.tx.length === 0" class="widget-holder">
                  <div class="widget-bg">
                    <div class="widget-body">
                      <div class="text-center py-4">
                        <h4 class="my-0"><p><i class="fal fa-credit-card fa-5x text-stroke-5 text-icon-gray"></i></p><p>No transaction history found.</p></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <b-modal id="updatemodal" title="Payment Account" size="xl" centered>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-row pr-lg-2">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label">Cardholder Name</label>
                      <input type="text" class="form-control" v-model="form.name" maxlength="100">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label">Company</label>
                      <input type="text" class="form-control" v-model="form.company" maxlength="100">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label">Address</label>
                      <input type="text" class="form-control" v-model="form.address1" maxlength="100">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="form.address2" maxlength="100">
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label class="col-form-label">City</label>
                      <input type="text" class="form-control" v-model="form.city" maxlength="50">
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="col-form-label"><span v-if="form.country === 'CA'">Province</span><span v-else>State</span></label>
                      <input type="text" class="form-control" v-model="form.state" maxlength="50">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"><span v-if="form.country === 'CA'">Postal Code</span><span v-else>Zip</span></label>
                      <input type="text" class="form-control" v-model="form.zip" maxlength="50">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label">Country</label>
                      <select v-model="form.country" class="form-control custom-select">
                        <option value="US">United States</option>
                        <option value="CA">Canada</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-row pl-lg-2 pt-4 pt-lg-0">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label">Credit Card Number</label>
                      <div class="input-group">
                        <div ref="cc" class="stripe-form-control form-control"></div>
                        <div class="input-group-append"><span class="input-group-text"><i class="fal fa-lock-alt fa-lg text-primary"></i></span></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Expiration Date</label>
                      <div ref="ccexpr" class="stripe-form-control form-control"></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Security Code</label>
                      <div class="input-group">
                        <div ref="ccv" class="stripe-form-control form-control"></div>
                        <div class="input-group-append"><span class="input-group-text"><i class="fal fa-info-circle fa-lg text-primary" v-b-tooltip.hover title="3-digit security code on the back of your card"></i></span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template v-slot:modal-footer="{ cancel }">
              <button type="button" class="btn btn-primary btn-rounded mr-2" @click="updateCard()">Save Changes</button>
              <button type="button" class="btn btn-default btn-rounded mr-auto" @click="cancel()">Cancel</button>
            </template>
          </b-modal>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ModalPlugin, TooltipPlugin } from 'bootstrap-vue';
import { loadStripe } from '@stripe/stripe-js';
import Header from '@/components/AccountHeader.vue';
import toast from '@/modules/toast';

Vue.use(ModalPlugin);
Vue.use(TooltipPlugin);

let stripe;
let elements;

export default {
  data() {
    return {
      settings: {
        tx: [],
      },
      form: {},
    };
  },
  computed: {
    balance() {
      let amount = 0;
      for (let i = 0; i < this.settings.tx.length; i += 1) {
        amount += this.settings.tx[i].amount;
      }
      return amount;
    },
    lastPayment() {
      for (let i = 0; i < this.settings.tx.length; i += 1) {
        if (this.settings.tx[i].amount > 0) {
          return `${this.$options.filters.formatAmount(this.settings.tx[i].amount, true)} on ${this.$datefns.utc.format(this.settings.tx[i].created_at, 'MMM d yyyy')}`;
        }
      }
      return '';
    },
    months() {
      const m = [];
      for (let i = 0; i < this.settings.tx.length; i += 1) {
        m.push(this.settings.tx[i].created_at.substring(0, 7));
      }
      return Array.from(new Set(m));
    },
    currentMonth() {
      const d = this.$datefns.utc.Date().toISOString();
      return d.substring(0, 7);
    },
  },
  filters: {
    formatAmount(v, includeDollarSign) {
      const amt = (Math.abs(v) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return `${v < 0 ? '(' : ''}${includeDollarSign ? '$' : ''}${amt}${v < 0 ? ')' : ''}`;
    },
    formatFrequency(v) {
      switch (v) {
        case '7 days':
          return 'Weekly';
        case '14 days':
          return 'Biweekly';
        case '1 mon':
          return 'Monthly';
        case '3 mons':
          return 'Quarterly';
        case '1 year':
          return 'Annual';
        default:
          return v;
      }
    },
    formatCountry(v) {
      switch (v) {
        case 'US':
          return 'United States';
        case 'CA':
          return 'Canada';
        default:
          return v;
      }
    },
  },
  methods: {
    beginningBalance(m) {
      for (let i = this.settings.tx.length - 1; i >= 0; i -= 1) {
        if (this.settings.tx[i].created_at.startsWith(m)) {
          return this.settings.tx[i].balance - this.settings.tx[i].amount;
        }
      }
      return 0;
    },
    endingBalance(m) {
      for (let i = 0; i < this.settings.tx.length; i += 1) {
        if (this.settings.tx[i].created_at.startsWith(m)) {
          return this.settings.tx[i].balance;
        }
      }
      return 0;
    },
    txMonthGroup(m) {
      const tx = [];
      for (let i = 0; i < this.settings.tx.length; i += 1) {
        if (this.settings.tx[i].created_at.startsWith(m)) {
          tx.push(this.settings.tx[i]);
        }
      }
      return tx;
    },
    async updateCard() {
      // hardcode frequency
      this.form.frequency = this.$config.billing_frequency;
      // stripe
      const addr = {
        name: this.form.name,
        address_line1: this.form.address1,
        address_line2: this.form.address2,
        address_city: this.form.city,
        address_state: this.form.state,
        address_zip: this.form.zip,
        address_country: this.form.country,
      };
      stripe.createToken(elements.getElement('cardNumber'), addr)
        .then((res) => {
          if (res.error) {
            toast.danger(this, res.error.message);
            return;
          }
          this.form.card_token = res.token.id;
          // save
          this.$api.post('/profile/billing', this.form)
            .then((r) => {
              this.setSettings(r.data);
              this.$bvModal.hide('updatemodal');
            })
            .catch((e) => {
              toast.danger(this, e.response.data.message);
            });
        });
    },
    async openUpdateModal() {
      this.$bvModal.show('updatemodal');
      // stripe
      stripe = await loadStripe(this.settings.stripe_key);
      elements = stripe.elements();
      const style = {
        base: {
          color: '#444',
          fontSize: '14px',
          '::placeholder': { color: '#bbb' },
        },
      };
      (elements.create('cardNumber', { style })).mount(this.$refs.cc);
      (elements.create('cardExpiry', { style })).mount(this.$refs.ccexpr);
      (elements.create('cardCvc', { style })).mount(this.$refs.ccv);
    },
    setTxBalances() {
      let balance = 0;
      for (let i = this.settings.tx.length - 1; i >= 0; i -= 1) {
        balance += this.settings.tx[i].amount;
        this.settings.tx[i].balance = balance;
      }
    },
    setSettings(v) {
      this.settings = v;
      this.setTxBalances();
      // copy to form
      this.form = JSON.parse(JSON.stringify(v));
      if (this.form.country === '') {
        this.form.country = 'US';
      }
      delete this.form.tx;
    },
  },
  async mounted() {
    if (!this.$auth.profile.enable_billing) {
      this.$store.commit('error', 404);
      return;
    }
    try {
      this.setSettings((await this.$api.get('/profile/billing')).data);
      this.$store.commit('ready', true);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
  },
};
</script>
